import { Tier } from 'app/queries/tiers/member-tiers'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'
import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const redeemCollectableFragment = gql`
  fragment redeemCollectableFragment on RedeemCollectable {
    id
    type
    stockItem {
      id
    }
    stockItems {
      stockItem {
        id
        name
      }
      label
      inStock
    }
    introText
    ineligibleText
    redeemedText
    successText
    redeemButtonText
    expiredText
    isEligible
    hasRedeemed
    isExpired
  }
`

export interface RedeemCollectable extends GenericBlock<'RedeemCollectable'> {
  id: string
  type: 'single' | 'multiple'
  stockItem: {
    id: string
  }
  stockItems: {
    stockItem: {
      id: string
      name: string
    }
    label: string
    inStock: boolean
  }[]
  introText: any
  ineligibleText: any
  redeemedText: any
  successText: any
  expiredText: any
  redeemButtonText: string
  isEligible: boolean
  hasRedeemed: boolean
  isExpired: boolean
  excludedPlatforms: ExcludedPlatforms
}
