import { ExcludedPlatforms } from "app/types/excludedPlatforms";
import { gql } from 'graphql-request'

import { GenericBlock } from "./generic";

export const buttonFragment = gql`
  fragment buttonFragment on Button {
    id
    buttonLabel: label
    buttonLink: href
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface Button extends GenericBlock<'Button'> {
  buttonLabel: string
  buttonLink: string
  excludedPlatforms?: ExcludedPlatforms
}
